
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'Rxjs/Observable';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";

import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})

export class ScoringService {


  constructor(private http: HttpClient) {

  }


  getScoring(inputs: any): Observable<any> {
    let key = '4385e96c5651144298131aaa46b18146a7cc6bfb';
    let scoringParameters = new HttpParams();


    scoringParameters = scoringParameters.append('geoloc', inputs.lat+','+inputs.lon).append('floor',inputs.floor);

    let options = { params: scoringParameters };
    return this.http.get("https://bdvapis.appspot.com/" + key + "/geostat/score?scorelist=luminosity,quietness,pollution,cooking,services,transportation,health,education,shopping,connectivity,safety,entertainment", options).pipe(

      map((data: any) => {

        delete data.results.transport;

        let scores = [];



        _.mapObject(data.results, (value, key) => { let o : any = {}; o.key= key; o.value = value ; scores.push(o);} )

        let sorted = _.sortBy(scores, (score) => { return score.value; });

        let globalScore = 0 ;
        _.map(scores, (sc) => {
          globalScore += sc.value;
        })

        let bdv_luminosity = scores[0].value;
        let bdv_quietness = scores[1].value;
        let bdv_pollution = scores[2].value;
        let bdv_cooking = scores[3].value;
        let bdv_services = scores[4].value;
        let bdv_transportation = scores[5].value;
        let bdv_health = scores[6].value;
        let bdv_education= scores[7].value;
        let bdv_shopping = scores[8].value;
        let bdv_connectivity = scores[9].value;
        let bdv_safety = scores[10].value;
        let bdv_entertainment = scores[11].value;

        let proxScore = Math.round((bdv_cooking + bdv_services + bdv_transportation + bdv_education + bdv_health + bdv_shopping + bdv_entertainment) / 7);
        let preFinalscore = Math.round(10 * (proxScore + bdv_luminosity + bdv_quietness + bdv_pollution + bdv_connectivity + bdv_safety) / 6) / 10;
        let scoreGen: number = parseInt((10 * preFinalscore) + '') / 10;

        /* map best scores with icons */
        return {
          'score1' : sorted[11],
          'score2' : sorted[10],
          'score3' : sorted[9],
          'score11' : sorted[0],
          'score10' : sorted[1],
          'score9' : sorted[2],
          'global' : scoreGen
        };
    }));

  }

}
