import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';

import {SCORES_ICONS, SCORES_LABELS} from '../static/score';
import {HundredCeilPipe} from '../pipes/hundred-ceil';
import {FrenchNumberPipe} from '../pipes/french-number';
import {FloatFormatPipe} from '../pipes/float-format';
import {DecimalPipe} from '@angular/common';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import * as _ from 'underscore';
import {LoginService} from '../services/login.service';
import {AdjustPricePipe} from '../pipes/adjustPrice';

@Component({
    selector: 'app-export',
    templateUrl: './export.component.html',
    styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {

    @Input() estimationResult: any;
    @Input() estimationForm: any;
    @Input() adjustPriceForm: any;
    @Input() referenceForm: any;
    @Input() orientationLabels: any;
    @Input() address: any;
    @Input() startPrinting: any;
    @Input() scores: any;
    @Input() htmlPdf: any;
    @Input() files: any;
    scoresIcons = SCORES_ICONS;
    scoresLabels = SCORES_LABELS;
    ceil: any;
    pageNumber = 4;
    frenshNumber: any;
    floatFormat: any;
    adjustPricePipe: any;
    charts = [
        {
            id: 'bubble-chart',
            background: 'rgb(242,249,251)',
            dimms: {
                w: 1000,
                h: 600
            }
        },
        // {
        //   id: 'value-reel',
        //   background: 'rgb(242,249,251)',
        //   dimms: {
        //     w: 1000,
        //     h: 650
        //   }
        // }
    ];
    @Output()
    finishPrinting: EventEmitter<any> = new EventEmitter();
    user: any;
    map: any;
    private pdfTemplate: any = '';

    constructor(private decimalPipe: DecimalPipe, private loginService: LoginService) {
        this.ceil = new HundredCeilPipe();
        this.frenshNumber = new FrenchNumberPipe();
        this.adjustPricePipe = new AdjustPricePipe();
        this.floatFormat = new FloatFormatPipe();
    }

    ngOnInit() {
        this.user = this.loginService.getUser();

    }

    addMap(parsedHtml) {
        // console.log(map);
        // console.log(map.getCanvas());
        var img = this.map.getCanvas().toDataURL('image/png');
        parsedHtml.getElementById('mapImage').src = img;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('startPrinting')) {
            let values: any = changes;
            let value = values.startPrinting.currentValue;
            if (value == true) {
                this.preparePdf();
            }
        }

        if (changes.hasOwnProperty('files')) {
            let values: any = changes;
            let files = values.files.currentValue;
            if (files) {
                this.files = files;
            }
        }

        if (changes.hasOwnProperty('adjustPriceForm')) {
            let values: any = changes;
            let adjustPriceForm = values.adjustPriceForm.currentValue;
            if (adjustPriceForm) {
                this.adjustPriceForm = adjustPriceForm;
            }
        }
    }


    preparePdf() {
        console.log(this.address);
        let parser = new DOMParser();
        let parsedHtml = parser.parseFromString(this.htmlPdf, 'text/html');
        this.fixColorGrids(parsedHtml);
        this.populatePage1(parsedHtml);
        this.populatePage2(parsedHtml);
        this.populatePage3(parsedHtml);
        this.populatePage5(parsedHtml);
        this.addMap(parsedHtml);
        this.pdfTemplate = new XMLSerializer().serializeToString(parsedHtml);

        setTimeout(() => {

            this.getChartsImages().then(charts => {
                if (charts[0]) {
                    let bubblePdf = document.getElementById('bubble-chart-pdf');
                    var img = charts[0].toDataURL('image/png');
                    bubblePdf['src'] = img;
                    let bubblePdfLegend = document.getElementById('bubble-chart-pdf-legend');
                    bubblePdfLegend.innerHTML = 'Voici les 30 points comparables les plus proches de l\'adresse ' + this.address.replace(/\(.*\)/, '');
                }

                if (charts[1]) {
                    let reelValue = document.getElementById('value-reel-chart-pdf');
                    var img = charts[1].toDataURL('image/png');
                    reelValue['src'] = img;
                    // reelValue.innerHTML = '';
                    // reelValue.appendChild(charts[1]);
                } else {
                    this.pageNumber--;
                }

                this.populateNbPage();

                setTimeout(() => {
                    this.generatePDF();
                }, 1000);
            }).catch((e) => {
                console.error(e);
            });
        }, 300);
    }

    private populateNbPage() {

        let spans = Array.from(document.getElementsByClassName('nb-page'));
        if (spans.length > 0) {
            spans.forEach(elt => {
                elt.innerHTML = this.pageNumber + '';
            });
        }

        let circles = Array.from(document.getElementsByTagName('circle'));
        if (circles.length > 0) {
            circles.forEach((elt: any) => {
                if (elt.classList.contains('Ellipse_11')) {
                    elt.style.fill = 'rgb(44, 146, 185)';
                } else if (elt.classList.contains('Ellipse_10')) {
                    elt.style.fill = 'rgb(64, 172, 213)';
                }
            });
        }

    }

    private getChartsImages() {

        return new Promise((resolve, reject) => {
            try {
                let canvasPages = this.charts.map((chart) => {
                    const elt = document.getElementById(chart.id);

                    if (!elt) {
                        return null;
                    }

                    return html2canvas(elt, {
                        useCORS: true,
                        width: chart.dimms.w,
                        height: chart.dimms.h,
                        allowTaint: true,
                        backgroundColor: chart.background
                    });

                });


                Promise.all(canvasPages).then((pagesPdf) => {
                    resolve(pagesPdf);
                }).catch((e) => {
                    console.error(e);
                });

            } catch (e) {
                console.error(e);
                reject(e);
            }

        });
    }

    private generatePDF() {
        let canvasPages = _.range(this.pageNumber + 1).map((obj) => {
            console.log('\'page\' + (obj + 1)', 'page' + (obj + 1));
            return html2canvas(document.getElementById('page' + (obj + 1)), {
                useCORS: true
            });
        });

        Promise.all(canvasPages).then((pagesPdf) => {
            let doc = new jspdf('l', 'mm', 'a4');
            let width = doc.internal.pageSize.width;
            let height = doc.internal.pageSize.height;

            pagesPdf.forEach((canvas: any, index) => {
                if (index) {
                    doc.addPage();
                }
                doc.addImage(canvas.toDataURL(), 'PNG', 0, 0, width, height);
            });
            this.pageNumber = 4;
            doc.save(`rapport-estimation-${this.formatDate(new Date(), true)}.pdf`);
            this.finishPrinting.emit('finish');
        }).catch((e) => {
            console.error(e);
        });

    }

    private populateRefValueList() {
        let childs = [];
        Object.keys(this.referenceForm.step4).forEach((key, index) => {
            if (this.referenceForm.step4[key].referenceType) {
                var liElement = document.createElement('li');
                var divLeftElement = document.createElement('div');
                var divRightElement = document.createElement('div');
                divLeftElement.className = 'left';
                divRightElement.className = 'right';
                divLeftElement.innerHTML = '<span>' + this.referenceForm.step4[key].referenceType.label + '</span>';
                divRightElement.innerHTML = `<span>${this.referenceForm.step4[key].referencePrice ? this.referenceForm.step4[key].referencePrice + ' €' : ''}</span>
        <span>${this.referenceForm.step4[key].referenceDate ? this.formatDate(new Date(this.referenceForm.step4[key].referenceDate)) : ''}</span>`;
                liElement.appendChild(divLeftElement);
                liElement.appendChild(divRightElement);
                childs.push(liElement);
                // childs += `
                //                  <li>
                //             <div class="left">
                //                 <span>${this.referenceForm.step4[key].referenceType.label}</span>
                //             </div>
                //             <div class="right">
                //                 <span>${this.referenceForm.step4[key].referencePrice ? this.referenceForm.step4[key].referencePrice + ' €' : ''}</ng-container></span>
                //                 <span>${this.referenceForm.step4[key].referenceDate ? this.formatDate(new Date(this.referenceForm.step4[key].referenceDate)) : ''}</span>
                //             </div>
                //         </li>
                //                 `;
            }
        });
        return childs;
    }

    private populatePage1(parsedHtml: Document) {
        parsedHtml.getElementById('address').innerHTML = this.estimationForm.step2.propertyType.label + ', ' + this.address.replace(/\(.*\)/, '').replace(/\,/, '<br>') + '<br> France';
        let infoContainer = parsedHtml.getElementsByClassName('col-info');
        //Réalisé le
        infoContainer.item(0).children[1].innerHTML = this.formatDate(new Date());
        //Identifiant de l'estimation
        infoContainer.item(1).children[1].innerHTML = (+new Date).toString(36);
        //Version du modèle
        // infoContainer.item(2).children[1].innerHTML = this.user._id;
    }

    private populatePage2(parsedHtml: Document) {
        let page2 = parsedHtml.getElementsByClassName('page-2')[0];
        let rights;
        let lefts;

        /*bloc localisation */

        const localisation = page2.children[1].children[0];
        //address
        localisation.children[1].getElementsByClassName('right')[0].children[0].innerHTML = this.address.replace(/\(.*\)/, '').split(', ')[0];
        localisation.children[1].getElementsByClassName('right')[0].children[1].innerHTML = this.address.replace(/\(.*\)/, '').split(', ')[1];


        const description = page2.children[1].children[1];

        rights = description.children[1].getElementsByClassName('right');
        lefts = description.children[1].getElementsByClassName('left');

        //Type de bien
        rights[0].children[0].innerHTML = this.estimationForm.step2.propertyType.id == '0' ? this.estimationForm.step2.apartmentType.label : this.estimationForm.step2.propertyType.label;

        //Nombre de pièces
        rights[1].children[0].innerHTML = this.estimationForm.step2.roomNb.id;

        //Surface habitable
        rights[2].children[0].innerHTML = this.estimationForm.step2.propertySurface + 'm²';

        //Usage';
        rights[3].children[0].innerHTML = this.estimationForm.step2.usage.label;


        /*bloc Paramètres appartement*/
        // const params = page2.children[1].children[2];
        //
        // rights = params.children[1].getElementsByClassName('right');

        //Type d'appartement
        // rights[0].children[0].innerHTML = 'Appartement en étage*';

        if (this.estimationForm.step2.propertyType.id == '0') {
            //Terrain / Jardin
            lefts[4].children[0].innerHTML = 'Terrain / Jardin';
            rights[4].children[0].innerHTML = this.estimationForm.step2.garden.label ? 'Oui' : 'Non';

            //Etage
            lefts[5].children[0].innerHTML = 'Étage';
            rights[5].children[0].innerHTML = this.estimationForm.step2.floor ? this.estimationForm.step2.floor.id : '';

            //Nombre d'étage d'immeuble
            lefts[6].children[0].innerHTML = 'Nombre d\'étages de l\'immeuble';
            rights[6].children[0].innerHTML = this.estimationForm.step2.floorNb ? this.estimationForm.step2.floorNb.id : '';

            //Nb de balcon(s)
            rights[7].children[0].innerHTML = this.estimationForm.step3.balconyNb;
        } else {
            //Surface de la parcelle cadastrale
            lefts[4].children[0].innerHTML = 'Surface de la parcelle cadastrale';
            rights[4].children[0].innerHTML = this.estimationForm.step2.landSurface + 'm²';

            //Le bien est-il mitoyen ?
            lefts[5].children[0].innerHTML = 'Le bien est-il mitoyen ?';
            rights[5].children[0].innerHTML = this.estimationForm.step2.houseType == '0' ? 'Non' : 'Oui';

            //Nombre d'étages de la maison
            lefts[6].children[0].innerHTML = 'Nombre d\'étages de la maison';
            rights[6].children[0].innerHTML = this.estimationForm.step2.floorNb ? this.estimationForm.step2.floorNb.id : '';

            //Nb de balcon(s)
            rights[7].children[0].style.display = 'none';
            lefts[7].children[0].style.display = 'none';
        }


        //Nombre de parking(s)
        rights[8].children[0].innerHTML = this.estimationForm.step3.parkingNb;


        //Annexes
        rights[9].children[0].innerHTML = [
            this.estimationForm.step3.concierge ? 'Concierge' : '',
            this.estimationForm.step3.elevator ? 'Ascenseur' : '',
            this.estimationForm.step3.cellaer ? 'Cave' : '',
            this.estimationForm.step3.swimmingPool ? 'Piscine' : '',
            this.estimationForm.step3.terace ? 'Terrasse' : '',
        ].filter(s => s).toString();

        rights[10].children[0].innerHTML = this.estimationForm.step3.view.label;


        /*bloc Détails*/
        const details = page2.children[1].children[2];
        details.children[0].innerHTML = 'Détails';
        rights = details.children[1].getElementsByClassName('right');

        //Standing
        rights[0].children[0].innerHTML = this.estimationForm.step3.standing.label;

        //Type de salle de bain
        rights[1].children[0].innerHTML = this.estimationForm.step3.bathroomType.label.split(': ')[0] + ':';
        rights[1].children[1].innerHTML = this.estimationForm.step3.bathroomType.label.split(': ')[1];

        //Type de cuisine
        rights[2].children[0].innerHTML = this.estimationForm.step3.kitchenType.label;

        //Type de chauffage
        rights[3].children[0].innerHTML = this.estimationForm.step3.heatingType.label;


        /*bloc ETAT DU BIEN */
        var state = page2.children[1].children[3];

        rights = state.children[1].getElementsByClassName('right');


        //Etat général
        rights[0].children[0].innerHTML = this.estimationForm.step3.propertyState.label;

        //Année de construction
        rights[1].children[0].innerHTML = this.estimationForm.step3.constructionYear.label;

        //Classe énergétique
        rights[2].children[0].innerHTML = this.estimationForm.step3.energyClass.label;

        //GES
        rights[3].children[0].innerHTML = this.estimationForm.step3.ges.label;

        //Etat Rénovation
        rights[4].children[0].innerHTML = this.estimationForm.step3.toBeRestored == 0 ? 'Oui' : 'Non';

        // rénovation
        rights[5].children[0].innerHTML = this.estimationForm.step3.state.label;

       /* //Vue
        rights[6].children[0].innerHTML = this.estimationForm.step3.view.label;*/

        //Orientation
        rights[6].children[0].innerHTML = this.orientationLabels.toString();

        /*bloc Autres indications */
        const other_indications = page2.children[1].children[4];

        if (!this.adjustPriceForm.adjustPrice) {
            other_indications.innerHTML = '';
        } else {
            rights = other_indications.children[1].getElementsByClassName('right');
            rights[0].children[0].innerHTML = this.getPrice(this.adjustPriceForm.adjustPrice);
            rights[1].children[0].innerHTML = this.adjustPriceForm.adjustPriceDescription;

        }


        /*bloc Autres indications */
        const ref_value = page2.children[1].children[5];


        /*bloc VALEURS DE RÉFÉRENCE */
        // let ref = page2.children[1].children[4];
        // let refValues = ref.children[1];

        const content = this.populateRefValueList();
        if (!content.length) {
            ref_value.innerHTML = '';
        } else {
            // if (content.length > 0) {
            // refValues.innerHTML = `<ul>${content}</ul>`;
            for (var i = 0; i < content.length; i++) {
                ref_value.children[1].appendChild(content[i]);
            }
            // }
        }


        /*bloc base de données */
        const data_meta = page2.children[1].children[6];
        rights = data_meta.children[1].getElementsByClassName('right');

        rights[0].children[0].innerHTML = this.estimationResult.sampleSize;

        rights[1].children[0].innerHTML = this.estimationResult.dataSize;
        // console.log('this.estimationResult', this.estimationResult);
        rights[2].children[0].innerHTML = '2019-Q1';

    }


    private populatePage3(parsedHtml: Document) {
        let page3 = parsedHtml.getElementsByClassName('page-3')[0];
        let rights;

        let getSquares = (value) => {
            return `<div class="square ${value == 1 ? 'green-color-bg' : ''}"></div>
        <div class="square ${value == 2 ? 'orange-color-bg' : ''}"></div>
        <div class="square ${value == 3 ? 'red-color-bg' : ''}"></div>`;
        };


        /*bloc AUTOUR DU BIEN */
        const proximity = page3.children[1].children[0];
        rights = proximity.children[1].getElementsByClassName('right');

        //Tension de la zone

        const zoneTypes = ['Zone distendue', 'Zone modérément tendue', 'Zone très tendue'];
        rights[0].children[0].innerHTML = zoneTypes[this.estimationResult.zone - 1];
        rights[0].children[1].innerHTML = getSquares(this.estimationResult.zone);

        //Position par rapport au bien typique
        const positionTypes = ['Très atypique', 'Atypique', 'Typique'];
        rights[1].children[0].innerHTML = positionTypes[this.estimationResult.position - 1];
        rights[1].children[1].innerHTML = getSquares(this.estimationResult.position == 1 ? 3 : this.estimationResult.position == 3 ? 1 : 2);


        //Attractivité touristique


        const hotelNbLabels = ['Zone peu touristique', 'Zone modéremment touristique', 'Zone très touristique'];
        let deg = this.estimationResult.hotelNb > 10 ? ((this.estimationResult.hotelNb < 30) ? 2 : 3) : 1;
        rights[2].children[0].innerHTML = hotelNbLabels[deg - 1];
        var arrowPositionPercentage = 0;
        if (this.estimationResult.hotelNb <= '0') {
            arrowPositionPercentage = 0;
        } else if (this.estimationResult.hotelNb <= '10') {
            arrowPositionPercentage = 33;
        } else if (this.estimationResult.hotelNb > '10' && this.estimationResult.hotelNb <= '30') {
            arrowPositionPercentage = 66;
        } else {
            arrowPositionPercentage = 100;
        }

        rights[2].children[1].children[0].style.left = 'calc(' + arrowPositionPercentage + '% - 8px)';
        // rights[2].children[1].innerHTML = getSquares(deg);
        // rights[2].children[1].className = 'jauge';


        //Hotels
        let leftHalf = proximity.children[1].getElementsByClassName('left-half')[0];
        let rightHalf = proximity.children[1].getElementsByClassName('right-half')[0];

        leftHalf.children[1].innerHTML = this.estimationResult.hotelNb + ' hôtels à proximité';
        rightHalf.children[1].innerHTML = Math.ceil(this.estimationResult.citizensPerHotelRoom) + ' habitants / chambre d\'hôtel';


        //MICRO-SITUATION
        const micro = page3.children[1].children[1];
        rightHalf = micro.children[1].getElementsByClassName('right-half')[0];
        leftHalf = micro.children[1].getElementsByClassName('left-half')[0];

        for (var i = 0; i < 3; i++) {
            leftHalf.children[1].children[i].children[0].className = this.scoresIcons[this.scores['score' + (i + 1)].key];
            leftHalf.children[1].children[i].children[1].children[0].innerHTML = this.scoresLabels[this.scores['score' + (i + 1)].key];
            leftHalf.children[1].children[i].children[1].children[1].innerHTML = this.floatFormat.transform(this.scores['score' + (i + 1)].value) + '<small>/10</small>';
        }

        for (var i = 0; i < 3; i++) {
            rightHalf.children[1].children[i].children[0].className = this.scoresIcons[this.scores['score' + (i == 0 ? 11 : i == 1 ? 10 : 9)].key];
            rightHalf.children[1].children[i].children[1].children[0].innerHTML = this.scoresLabels[this.scores['score' + (i == 0 ? 11 : i == 1 ? 10 : 9)].key];
            rightHalf.children[1].children[i].children[1].children[1].innerHTML = this.floatFormat.transform(this.scores['score' + (i == 0 ? 11 : i == 1 ? 10 : 9)].value) + '<small>/10</small>';
        }


        //Estimation

        const estimation = page3.children[1].children[2];

        let fillEstimation = (i, value) => {
            estimation.children[1].children[0].children[i].children[1].innerHTML = this.getPrice(this.adjustPricePipe.transform(value, this.adjustPriceForm.adjustPrice));
        };

        fillEstimation(0, this.estimationResult.valuation.priceRangeMin);
        fillEstimation(1, this.estimationResult.valuation.price);
        fillEstimation(2, this.estimationResult.valuation.priceRangeMax);


        const pricem2 = page3.children[1].children[2].children[1].children[1];
        const pricem2Estimated = this.estimationResult.valuation.price / this.estimationForm.step2.propertySurface;
        pricem2.children[0].children[1].children[0].innerHTML = this.getPrice(pricem2Estimated) + '</br> (Valeur estimée / Surface Loi Carrez)';


        //PRIX VIRTUEL

        const virtuel = page3.children[1].children[3];
        let fillVirtuel = (i, value) => {
            virtuel.children[1].children[0].children[i].children[1].innerHTML = this.getPrice(value);
        };

        fillVirtuel(0, this.estimationResult.virtualValueM2.priceRangeMin * this.estimationForm.step2.propertySurface);
        fillVirtuel(1, this.estimationResult.virtualValueM2.price * this.estimationForm.step2.propertySurface);
        fillVirtuel(2, this.estimationResult.virtualValueM2.priceRangeMax * this.estimationForm.step2.propertySurface);


        // PRIX DU M² VIRTUEL
        const pricem2virtual = page3.children[1].children[3].children[1].children[1];
        const pricem2virtualEstimated = this.estimationResult.virtualValueM2.price;
        pricem2virtual.children[0].children[1].children[0].innerHTML = this.getPrice(pricem2virtualEstimated) + '</br> (Valeur théorique / Surface Loi Carrez)';
        //
        // const virtuel = page3.children[1].children[3];
        //
        // let fillVirtuel = (i, value) => {
        //   virtuel.children[0].children[i].children[1].innerHTML = this.getPrice(value);
        // };
        //
        // fillVirtuelm(0, this.estimationResult.virtualValueM2.priceRangeMin);
        // fillVirtuelm(1, this.estimationResult.virtualValueM2.price);
        // fillVirtuelm(2, this.estimationResult.virtualValueM2.priceRangeMax);

        if (this.estimationForm.step2.propertyType.id == '1') {
            //valeur reele
            page3.children[1].children[4]['style']['display'] = 'block';
            const realValue = page3.children[1].children[4].children[1];
            realValue.children[0].children[1].children[0].innerHTML = this.decimalPipe.transform((this.estimationResult.valuation.price - this.estimationResult.realValue.price) / this.estimationResult.valuation.price * 100, '2.0-0') + '%';
            realValue.children[1].children[1].children[0].innerHTML = this.getPrice(Math.round(this.estimationResult.realValue.price / 1000) * 1000);
            realValue.children[2].children[1].children[0].innerHTML = this.getPrice(Math.round(this.estimationResult.realValue.terrainPrice / 1000) * 1000);
            realValue.children[3].children[1].children[0].innerHTML = this.getPrice(this.estimationResult.realValue.terrainPrice / this.estimationForm.step2.landSurface);
        }
    }


    private populatePage5(parsedHtml: Document) {
        let filesPages = chunk(this.files, 6);
        filesPages.forEach((partFiles) => {
            this.pageNumber++;
            const page = parsedHtml.getElementsByClassName('page-' + this.pageNumber)[0];
            if (!page) {
                return;
            }
            let imagesDiv = page.getElementsByClassName('image-item');
            partFiles.forEach((f, index) => {
                this.appendImageBlock(imagesDiv[index], f);
            });
        });
    }

    private appendImageBlock(parent, file) {

        const img = document.createElement('img');
        img.style.backgroundImage = 'url(\'' + URL.createObjectURL(file) + '\')';
        img.className = 'img';
        parent.appendChild(img);

        const div = document.createElement('div');
        div.className = 'title';

        const span = document.createElement('span');
        span.innerHTML = file.title;
        div.appendChild(span);

        parent.appendChild(div);


    }

    private getPrice(number) {
        return this.frenshNumber.transform(this.decimalPipe.transform(number, '2.0-0')) + ' €';
    }

    private hundredCeilPrice(number) {
        return this.frenshNumber.transform(this.ceil.transform(number)) + ' €';
    }

    private fixColorGrids(parsedHtml) {
        let rectElements = Array.from(document.getElementsByTagName('line'));
        if (rectElements.length > 0) {
            rectElements.forEach(rect => {
                rect.style.fill = '#ddd';
                rect.style.stroke = '#ddd';
            });
        }
    }

    private formatDate(date, name = false) {
        let day = ('0' + date.getDate()).slice(-2);
        let monthIndex = ('0' + (date.getMonth() + 1)).slice(-2);
        return !name ? day + '/' + monthIndex + '/' + date.getFullYear() : day + '-' + monthIndex + '-' + date.getFullYear();
    }


}


function chunk(array, size) {
    const chunked_arr = [];
    for (let i = 0; i < array.length; i++) {
        const last = chunked_arr[chunked_arr.length - 1];
        if (!last || last.length === size) {
            chunked_arr.push([array[i]]);
        } else {
            last.push(array[i]);
        }
    }
    return chunked_arr;
}
