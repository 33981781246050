import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'floatFormat'})
export class FloatFormatPipe implements PipeTransform {

    transform(value:any) {
        if (value) {
          return Math.round(value * 10) / 10;
        }
    }
}
