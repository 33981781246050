import { Component, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  login: string;
  password: string;
  showError = false;

  constructor(private loginService: LoginService, private router: Router) { }

  ngOnInit() {
    if (this.loginService.isLoggedIn()) {
      this.router.navigateByUrl('/');
    }
  }

  validateLogin() {
    this.loginService.login(this.login, this.password).subscribe((res) => {
      this.router.navigateByUrl('/');
    }, (err) => {
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 2500);
    });
  }

}
