import {Component, OnInit, ViewChild, TemplateRef, NgZone} from '@angular/core';
import {FormsModule, FormGroup, FormControl, FormArray, Validators, FormBuilder} from '@angular/forms';
import {Observable} from 'rxjs/Observable';

import {AutocompleteService} from '../../services/autocomplete.service';
import {EstimationService} from '../../services/estimation.service';
import {ScoringService} from '../../services/scoring.service';

import {HundredCeilPipe} from '../../pipes/hundred-ceil';
import {FrenchNumberPipe} from '../../pipes/french-number';
import {CleanNumberPipe} from '../../pipes/clean-number';
import {ActivatedRoute, Router} from '@angular/router';


import {DecimalPipe} from '@angular/common';

import 'rxjs/Rx';

import {HttpClient} from '@angular/common/http';
/* import static data - to mapping inouts */
import {
    APARTEMENT_TYPES,
    APPART_FlOORS,
    BATHROOM_TYPES,
    CONSTRUCTION_YEARS,
    ENERGY_CLASSES,
    GES_CLASSES,
    VIEWS_LIST,
    RENOVATION_LIST,
    HEATING_TYPES,
    KITCHEN_TYPES,
    PROPERTY_STATES,
    PROPERTY_TYPES,
    REFERENCE_TYPES,
    ROOMS_NUMBER,
    STANDING_RATES,
    TOTAL_FlOORS,
    USAGE_TYPES, ORIENTATION_LIST
} from '../../static/inputs';
import {SCORES_ICONS, SCORES_LABELS} from '../../static/score';

import {AccordionComponent} from 'ngx-bootstrap';
import {ModalModule} from 'ngx-bootstrap';
import {BsModalService, ModalDirective} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {BsDatepickerModule} from 'ngx-bootstrap';
import {CompleterItem} from 'ng2-completer';
import {Marker} from 'mapbox-gl';
import {LoginService} from '../../services/login.service';
import {AdjustPricePipe} from '../../pipes/adjustPrice';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [HundredCeilPipe, DecimalPipe, FrenchNumberPipe, CleanNumberPipe]
})
export class HomeComponent implements OnInit {

    // autocompleteData: AutocompleteService;
    //Form decalaration
    estimationForm: FormGroup;
    referenceForm: FormGroup;
    adjustPriceForm: FormGroup;
    files: File[] = [];

    /* Do we have to declare all of them, knowing that we declare them later */
    //Inputs decalaration
    modalRef: BsModalRef;
    modalRefAutocomplete: BsModalRef;

    // --> Step 1
    address: FormControl;
    lon: FormControl;
    lat: FormControl;

    // --> Step 2
    propertyType: FormControl;
    roomNb: FormControl;
    apartmentType: FormControl;
    floor: FormControl;
    floorNb: FormControl;
    usage: FormControl;
    houseType: FormControl;
    propertySurface: FormControl;
    garden: FormControl;
    landSurface: FormControl;
    constructionYear: FormControl;

    toBeRestored: FormControl;
    north: FormControl;
    east: FormControl;
    west: FormControl;
    south: FormControl;

    // --> Step 3
    standing: FormControl;
    propertyState: FormControl;
    bathroomType: FormControl;
    kitchenType: FormControl;
    energyClass: FormControl;
    ges: FormControl;
    view: FormControl;
    state: FormControl;
    heatingType: FormControl;
    balconyNb: FormControl;
    parkingNb: FormControl;
    renovAmount: FormControl;
    interiorRenovation: FormControl;
    interiorRenovationDate: FormControl;
    exteriorRenovation: FormControl;
    exteriorRenovationDate: FormControl;

    concierge: FormControl;
    elevator: FormControl;
    cellaer: FormControl;
    swimmingPool: FormControl;
    terace: FormControl;

    // --> Step 4
    referenceType: FormControl;
    referencePrice: FormControl;
    referenceDate: FormControl;


    inseeCode: any;
    startUpload: any;

    coordinates: number[];
    coordinatesLng: number;
    coordinatesLat: number;
    color = '#006186';

    // -> Select inputs arrays
    propertyTypes = PROPERTY_TYPES;
    usageTypes = USAGE_TYPES;
    constructionYears = CONSTRUCTION_YEARS;
    roomsNumber = ROOMS_NUMBER;
    floors = APPART_FlOORS;
    floorNbs = TOTAL_FlOORS;
    energyClasses = ENERGY_CLASSES;
    gesClasses = GES_CLASSES;
    viewslist = VIEWS_LIST;
    statelist = RENOVATION_LIST;
    orientationlist = ORIENTATION_LIST;
    heatingTypes = HEATING_TYPES;
    propertyStates = PROPERTY_STATES;
    standingRates = STANDING_RATES;
    bathroomTypes = BATHROOM_TYPES;
    kitchenTypes = KITCHEN_TYPES;
    referenceTypes = REFERENCE_TYPES;
    scoresIcons = SCORES_ICONS;
    scoresLabels = SCORES_LABELS;
    apartmentTypes = APARTEMENT_TYPES;

    estimationResult: any;

    //Touristic zone
    touristic: boolean = true;
    buildingHeight: number;
    realValueGround: number;
    realValueGroundSquarePrice: number;
    realValueState: number;
    groundHeight: number;

    estimationPosition: number;

    //Used for ngx-bootstrap's sutom accordion styling
    // TODO: Rename customClass to an explicit name class
    customClass: string = 'customClass';

    //Used for ngx-bootstrap's sutom accordion behavior
    isFirstOpen: boolean = true;
    oneAtATime: boolean = true;
    isPreAccordionOpen: boolean = true;
    isFirstAccordionOpen: boolean = false;
    isSecondAccordionOpen: boolean = false;
    isThirdAccordionOpen: boolean = false;

    /* pdf */

    printPdf: any;
    isPdfLoading: boolean = false;


    /* Wordings */
    //Used for form validation error
    isFormInvalid: string = 'Certains champs sont manquants';
    isAddressNotValid: string = 'L\'adresse doit se trouver dans les départements de l\'Ain (01) ou de la Haute-Savoie (74)';

    //Used for open result after form validation
    openResult: boolean = false;

    //Used to highlight input when list's item is clicked
    isClicked: string;

    addressIsVisible: boolean = true;
    gpsIsVisible: boolean = false;

    amountOfReferences: 0;

    refNumber: any;
    references: any[] = [];

    isLoading: boolean = false;
    firstOne: any;

    scores: any;


    /* do we use this? */
    countLoading: 2;

    isGPSCollapsed = false;
    isMapCollapsed = false;

    displayDetails = false;

    viewChart: any[] = [900, 450];

    // options
    showXAxis = true;
    showYAxis = true;

    showLegend = true;
    showXAxisLabel = true;
    xAxisLabel = 'Surface habitable (m²)';
    showYAxisLabel = true;
    yAxisLabel = 'Prix au m² habitable (euros)';
    legendTitle = 'Comparables';

    // TODO: rename this variable name please
    colorScheme = {
        // domain: ['#1abc9c', '#e67e22', '#ef717a', '#006186']
        domain: ['#1abc9c', '#e67e22', '#006186']
    };

    /* rename this variable, ça ne veut rien dire : data. Pareil pour loopData*/
    data: any[] = [];
    loopData: any;

    distanceMax: number;

    arrowPositionPercentage: number = 0;
    estimatedHouseInseecode: number;

    adressToDisplay: any;
    addressFromCoordinates = '';
    htmlPdf: any;
    orientationValues = [];
    @ViewChild(AccordionComponent) accordion: AccordionComponent;
    @ViewChild('modalBeforeResult') modalBeforeResult: TemplateRef<any>;
    @ViewChild('AdjustPriceResult') AdjustPriceResult: TemplateRef<any>;
    @ViewChild('modalUploadImages') modalUploadImages: TemplateRef<any>;
    @ViewChild('mapAutocompleteTemplate') mapAutocompleteTemplate: TemplateRef<any>;
    private orientationLabels = [];

    constructor(
        private http: HttpClient,
        private autocompleteData: AutocompleteService,
        private estimationService: EstimationService,
        private scoringService: ScoringService,
        private modalService: BsModalService,
        private hundredCeil: HundredCeilPipe,
        private adjustPricePipe: AdjustPricePipe,
        private decimalPipe: DecimalPipe,
        private frenchNumber: FrenchNumberPipe,
        private cleanNumber: CleanNumberPipe,
        private loginService: LoginService,
        private router: Router
    ) {
        this.autocompleteData = autocompleteData;
    }


    openModal(modalBeforeResult: TemplateRef<any>) {
        this.modalRef = this.modalService.show(modalBeforeResult);
    }

    openModalAutoComplete(mapAutocompleteTemplate: TemplateRef<any>) {
        this.modalRefAutocomplete = this.modalService.show(mapAutocompleteTemplate);
    }

    ngOnInit() {
        if (!this.loginService.isLoggedIn()) {
            this.router.navigateByUrl('/login');
        }
        window.scrollTo(0, 0);
        this.createFormControls();
        this.createForms();
        this.isFirstAccordionOpen = true;
        this.refNumber = 1;
        // this.http.get('http://localhost:8080/', {responseType: 'text'}).subscribe((data: any) => {
            this.http.get('https://asset.bondevisite.fr/voisine/pdf-dev/', {responseType: 'text'}).subscribe((data: any) => {
            this.htmlPdf = data;
        }, (error) => {
            console.log('error', error);
        });
    }

    deg2rad(deg) {
        return deg * (Math.PI / 180);
    }

    addNewReference() {
        if (this.referenceForm.controls.step4['controls']['ref' + this.refNumber].controls.referencePrice.value > 0) {
            this.refNumber++;
        } else {
            console.log('error');
        }
    }

    createFormControls() {

        // this.address = new FormControl("", [Validators.required, this.validateAddressValidator.bind(this)]);
        this.address = new FormControl('', Validators.required);
        this.lat = new FormControl({value: '46.191021361969234', disabled: true}, Validators.required);
        this.lon = new FormControl({value: '6.234000741984062', disabled: true}, Validators.required);

        this.propertyType = new FormControl('', Validators.required);
        this.roomNb = new FormControl('', Validators.required);
        this.apartmentType = new FormControl('');
        this.floor = new FormControl(0, Validators.required);
        this.floorNb = new FormControl('', Validators.required);
        this.usage = new FormControl('');
        this.houseType = new FormControl(0, Validators.required);
        this.propertySurface = new FormControl('', Validators.required);
        this.garden = new FormControl(0, Validators.required);
        this.landSurface = new FormControl(0, Validators.required);

        this.constructionYear = new FormControl('', Validators.required);
        this.toBeRestored = new FormControl('');
        this.north = new FormControl(0);
        this.east = new FormControl(0);
        this.west = new FormControl(0);
        this.south = new FormControl(0);

        this.standing = new FormControl('', Validators.required);
        this.propertyState = new FormControl('', Validators.required);
        this.bathroomType = new FormControl('', Validators.required);
        this.kitchenType = new FormControl('', Validators.required);
        this.energyClass = new FormControl('', Validators.required);
        this.ges = new FormControl('', Validators.required);
        this.view = new FormControl('');
        this.state = new FormControl('');
        this.renovAmount = new FormControl(0);
        this.interiorRenovation = new FormControl(0);
        this.interiorRenovationDate = new FormControl('');
        this.exteriorRenovation = new FormControl(0);
        this.exteriorRenovationDate = new FormControl('');

        this.balconyNb = new FormControl(0, Validators.required);
        this.parkingNb = new FormControl('', Validators.required);
        this.heatingType = new FormControl('', Validators.required);
        this.concierge = new FormControl(0, Validators.required);
        this.elevator = new FormControl(0, Validators.required);
        this.cellaer = new FormControl(0, Validators.required);
        this.swimmingPool = new FormControl(0, Validators.required);
        this.terace = new FormControl(0);
    }


    validateAddressValidator(address: FormControl) {

        if (address && this.inseeCode) {
            let isConsidered = this.inseeCode.substring(0, 2) == '01' || this.inseeCode.substring(0, 2) == '74';
            return isConsidered ? null : {
                validateAddress: {
                    valid: false
                }
            };
        }
    }

    createForms() {
        //Form Groups
        this.estimationForm = new FormGroup({
            step1: new FormGroup({
                address: this.address,
                lat: this.lat,
                lon: this.lon
            }),
            step2: new FormGroup({
                propertyType: this.propertyType,
                roomNb: this.roomNb,
                apartmentType: this.apartmentType,
                floor: this.floor,
                floorNb: this.floorNb,
                usage: this.usage,
                houseType: this.houseType,
                propertySurface: this.propertySurface,
                garden: this.garden,
                landSurface: this.landSurface
            }),
            step3: new FormGroup({
                standing: this.standing,
                propertyState: this.propertyState,
                bathroomType: this.bathroomType,
                kitchenType: this.kitchenType,
                constructionYear: this.constructionYear,
                energyClass: this.energyClass,
                ges: this.ges,
                view: this.view,
                state: this.state,
                renovAmount: this.renovAmount,
                interiorRenovation: this.interiorRenovation,
                exteriorRenovation: this.exteriorRenovation,

                balconyNb: this.balconyNb,
                parkingNb: this.parkingNb,
                heatingType: this.heatingType,
                concierge: this.concierge,
                elevator: this.elevator,
                cellaer: this.cellaer,
                swimmingPool: this.swimmingPool,
                terace: this.terace,
                toBeRestored: this.toBeRestored,
                north: this.north,
                south: this.south,
                east: this.east,
                west: this.west,
            })
        });

        this.referenceForm = new FormGroup({
            step4: new FormGroup({
                ref1: new FormGroup({
                    referenceType: new FormControl(),
                    referencePrice: new FormControl(),
                    referenceDate: new FormControl(new Date())
                }),
                ref2: new FormGroup({
                    referenceType: new FormControl(),
                    referencePrice: new FormControl(),
                    referenceDate: new FormControl(new Date())
                }),
                ref3: new FormGroup({
                    referenceType: new FormControl(),
                    referencePrice: new FormControl(),
                    referenceDate: new FormControl(new Date())
                }),
                ref4: new FormGroup({
                    referenceType: new FormControl(),
                    referencePrice: new FormControl(),
                    referenceDate: new FormControl(new Date())
                }),
                ref5: new FormGroup({
                    referenceType: new FormControl(),
                    referencePrice: new FormControl(),
                    referenceDate: new FormControl(new Date())
                }),
                ref6: new FormGroup({
                    referenceType: new FormControl(),
                    referencePrice: new FormControl(),
                    referenceDate: new FormControl(new Date())
                }),
                ref7: new FormGroup({
                    referenceType: new FormControl(),
                    referencePrice: new FormControl(),
                    referenceDate: new FormControl(new Date())
                }),
                ref8: new FormGroup({
                    referenceType: new FormControl(),
                    referencePrice: new FormControl(),
                    referenceDate: new FormControl(new Date())
                }),
            })
        });


        this.adjustPriceForm = new FormGroup({
            adjustPrice: new FormControl(''),
            adjustPriceDescription: new FormControl('')
        });

    }

    onAddressSelected(selectedAddress) {
        if (selectedAddress) {
            this.inseeCode = selectedAddress.inseeCode;
            this.autocompleteData.getCoordinates(selectedAddress.title).subscribe(
                (latlng) => {
                    this.lon.patchValue(latlng.lon);
                    this.lat.patchValue(latlng.lat);
                    this.address.patchValue(selectedAddress.title);
                }
            );
            this.address.updateValueAndValidity({onlySelf: true});
            if (this.validateAddressValidator(this.address) == null || this.validateAddressValidator(this.address).validateAddress.valid) {
                this.openModal(this.mapAutocompleteTemplate);
            }
        }
    }

    //Those two functions are used to switch between adresse autocomplete input and gps coordinates input
    turnAddressAutocomplete() {
        this.isMapCollapsed = false;
        this.isGPSCollapsed = false;

        this.lon.disable();
        this.lat.disable();
        this.address.enable();

        this.addressIsVisible = true;
        this.gpsIsVisible = false;
    }

    turnGPSOn() {
        this.isGPSCollapsed = !this.isGPSCollapsed;
        this.isMapCollapsed = false;

        this.lon.enable();
        this.lat.enable();
        this.address.disable();

        this.addressIsVisible = false;
        this.gpsIsVisible = true;
    }

    onSubmit() {
        if (this.estimationForm.valid) {
            this.openModal(this.modalBeforeResult);
            if (this.addressIsVisible) {
                this.adressToDisplay = this.address.value;
            }
        } else {
            console.log('un problème est survenu, vérifier les paramètres');
        }
    }

    onSubmitReference() {
        if (this.referenceForm.valid) {
            this.isLoading = true;
            this.modalRef.hide();
            let estimationParameters = this.estimationForm.value;
            let referenceParameters = this.referenceForm.value;
            /* Add cityCode */
            estimationParameters.step1.inseeCode = this.inseeCode;

            if (estimationParameters.step1.address && (!estimationParameters.step1.lon || !estimationParameters.step1.lat)) {
                delete estimationParameters.step1.address;
                estimationParameters.step1.lon = this.lon.value;
                estimationParameters.step1.lat = this.lat.value;
                this.getEstimation(estimationParameters, referenceParameters);
            } else if (estimationParameters.step1.lat && estimationParameters.step1.lon && !estimationParameters.step1.address) {
                this.autocompleteData.getAddressFromCoordinates(estimationParameters.step1.lon, estimationParameters.step1.lat).subscribe(
                    (res) => {
                        this.adressToDisplay = res.address.road_name_code;
                        this.address.patchValue(this.adressToDisplay);
                    }
                );
                this.autocompleteData.getInseeCode(estimationParameters.step1.lon, estimationParameters.step1.lat).subscribe(
                    (res) => {
                        estimationParameters.step1.inseeCode = res;
                        this.getEstimation(estimationParameters, referenceParameters);
                    }
                );
            }
            window.scrollTo(0, 0);
        }
    }

    getEstimation(estimationParameters, referenceParameters) {
        /* deleted all new parameters, not accepted in the api, add here any new parameter */
        delete estimationParameters.step3.renovAmount;
        delete estimationParameters.step3.interiorRenovation;
        delete estimationParameters.step3.exteriorRenovation;
        // delete estimationParameters.step2.landSurface

        //add orientation checkboxs
        estimationParameters.step3.orientationArray = this.orientationValues.toString();


        let scoringParameters = {
            'lon': this.lon.value,
            'lat': this.lat.value,
            'floor': estimationParameters.step2.floor.value ? estimationParameters.step2.floor.value : 2
        };

        this.estimationService.getEstimation(estimationParameters, referenceParameters).subscribe(
            (res) => {
                this.estimationResult = res;
                console.log(res, 'res');
                this.drawRealValueScheme();
                this.drawVirtualValueScheme();
                this.drawChart();
                this.arrowPosition();
                this.distanceMax = res.maxDistance ? (res.maxDistance / 1000) : 20;
                this.isLoading = false;
                this.openResult = true;
                this.scoringService.getScoring(scoringParameters).subscribe(
                    (res) => {
                        this.scores = res;
                    }
                );

            }
        );
    }

    arrowPosition() {
        if (this.estimationResult.hotelNb <= '0') {
            this.arrowPositionPercentage = 0;
        } else if (this.estimationResult.hotelNb <= '10') {
            this.arrowPositionPercentage = 33;
        } else if (this.estimationResult.hotelNb > '10' && this.estimationResult.hotelNb <= '30') {
            this.arrowPositionPercentage = 66;
        } else {
            this.arrowPositionPercentage = 100;
        }
    }

    drawChart() {

        // this.data = [
        //   {
        //     "name": "Bien similaires dans le même code insee ",
        //     "series": [
        //
        //     ]
        //   },
        //   {
        //     "name": "Biens similaires à moins de 10km",
        //     "series": [
        //
        //     ]
        //   },
        //   {
        //     "name": "Biens similaires en France",
        //     "series": [
        //
        //     ]
        //   },
        //   {
        //     "name": "Positionnement (bien estimé)",
        //     "series": [
        //
        //     ]
        //   }];

        this.data = [
            {
                'name': 'Marché actuel (Annonces)',
                'series': []
            },
            {
                'name': 'Transactions historiques',
                'series': []
            },
            {
                'name': 'Positionnement (bien estimé)',
                'series': []
            }];

        let radius = 20;
        for (let i = 0; i < this.estimationResult.ads.length; i++) {
            let priceCleaned = this.decimalPipe.transform(this.adjustPricePipe.transform(this.estimationResult.ads[i].prices.price, this.adjustPriceForm.controls.adjustPrice.value) / this.estimationResult.ads[i].surfaces.property, '1.0-0');
            let prices = parseFloat(this.cleanNumber.transform(priceCleaned));
            let surface = parseFloat(this.estimationResult.ads[i].surfaces.property);
            this.data[0].series.push({
                'name': '',
                'x': surface,
                'y': prices,
                'r': radius
            });
        }

        for (let i = 0; i < this.estimationResult.assessedValues.length; i++) {
            let priceCleaned = this.decimalPipe.transform(this.estimationResult.assessedValues[i].prices.net_seller / this.estimationResult.assessedValues[i].surfaces.property, '1.0-0');
            let prices = parseFloat(this.cleanNumber.transform(priceCleaned));
            let surface = parseFloat(this.estimationResult.assessedValues[i].surfaces.property);
            this.data[1].series.push({
                'name': '',
                'x': surface,
                'y': prices,
                'r': radius
            });
        }

        let propertyPriceBrut = this.decimalPipe.transform(this.adjustPricePipe.transform(this.estimationResult.valuation.price, this.adjustPriceForm.controls.adjustPrice.value) / this.propertySurface.value, '1.0-0');
        let propertyPrice = parseFloat(this.cleanNumber.transform(propertyPriceBrut));

        this.data[2].series.push({
            'name': '',
            'x': this.propertySurface.value,
            'y': propertyPrice,
            'r': 100
        });

        // this.estimatedHouseInseecode = this.estimationForm.value.step1.inseeCode;
        //
        // for (let i = 0; i < this.estimationResult.ads.length; i++) {
        //
        //   if (this.estimationResult.ads[i].location_details.insee_code == this.estimatedHouseInseecode ) {
        //     let priceCleaned  =  this.decimalPipe.transform(this.estimationResult.ads[i].prices.price / this.estimationResult.ads[i].surfaces.property, "1.0-0");
        //     let prices = parseFloat(this.cleanNumber.transform(priceCleaned));
        //     let surface = parseFloat(this.estimationResult.ads[i].surfaces.property);
        //     let radius = 20;
        //
        //     if (this.estimationResult.ads[i].surfaces.property > 0) {
        //
        //       this.data[0].series.push(
        //         {
        //           "name": "",
        //           "x": surface,
        //           "y": prices,
        //           "r": radius
        //         }
        //       )
        //     }
        //
        //   } else if (this.estimationResult.ads[i].distance <= 10000 ) {
        //       let priceCleaned  =  this.decimalPipe.transform(this.estimationResult.ads[i].prices.price / this.estimationResult.ads[i].surfaces.property, "1.0-0");
        //       let prices = parseFloat(this.cleanNumber.transform(priceCleaned));
        //       let surface = parseFloat(this.estimationResult.ads[i].surfaces.property);
        //       let radius = 20;
        //
        //       if (this.estimationResult.ads[i].surfaces.property > 0) {
        //
        //         this.data[1].series.push(
        //           {
        //             "name": "",
        //             "x": surface,
        //             "y": prices,
        //             "r": radius
        //           }
        //         )
        //       }
        //     } else {
        //       let priceCleaned  =  this.decimalPipe.transform(this.estimationResult.ads[i].prices.price / this.estimationResult.ads[i].surfaces.property, "1.0-0");
        //       let prices = parseFloat(this.cleanNumber.transform(priceCleaned));
        //       let surface = parseFloat(this.estimationResult.ads[i].surfaces.property);
        //       let radius = 20;
        //
        //       if (this.estimationResult.ads[i].surfaces.property > 0) {
        //
        //         this.data[2].series.push(
        //           {
        //             "name": "",
        //             "x": surface,
        //             "y": prices,
        //             "r": radius
        //           }
        //         )
        //       }
        //     }
        //
        //     let propertyPriceBrut =  this.decimalPipe.transform(this.estimationResult.valuation.price / this.propertySurface.value, "1.0-0");
        //     let propertyPrice = parseFloat(this.cleanNumber.transform(propertyPriceBrut));
        //
        //     this.data[3].series.push({
        //       "name": "",
        //       "x": this.propertySurface.value,
        //       "y": propertyPrice,
        //       "r": 100
        //     })
        //   }
    }

    drawVirtualValueScheme() {
        this.estimationPosition = (this.adjustPricePipe.transform(this.estimationResult.valuation.price, this.adjustPriceForm.controls.adjustPrice.value) / (this.estimationResult.virtualValueM2.price * this.propertySurface.value)) * 50;

        if (this.estimationResult.valuation.price > (this.estimationResult.virtualValueM2.priceRangeMax * this.propertySurface.value)) {
            this.estimationPosition = 101;
        }

        if (this.estimationResult.valuation.price < (this.estimationResult.virtualValueM2.priceRangeMin * this.propertySurface.value)) {
            this.estimationPosition = 0;
        }
    }

    drawRealValueScheme() {

        // this.squarePricRealValueGround = estimationResult.valuation.price - estimationResult.realValue.price;
        const estimationPrice = this.adjustPricePipe.transform(this.estimationResult.valuation.price, this.adjustPriceForm.controls.adjustPrice.value);
        const ratioAdjust = estimationPrice / this.estimationResult.valuation.price;
        this.buildingHeight = (this.estimationResult.realValue.price / estimationPrice) * 100;
        this.realValueGround = estimationPrice - this.estimationResult.realValue.price * ratioAdjust;
        this.realValueGroundSquarePrice = this.realValueGround / this.landSurface.value;
        this.realValueState = this.estimationResult.realValue.price * ratioAdjust;
        this.groundHeight = 100 - this.buildingHeight;

        if (this.buildingHeight > 75) {
            this.buildingHeight = 75;
            this.groundHeight = 25;
        }
    }

    onClickHiglight(event) {
        let target = event.target || event.srcElement || event.currentTarget;
        let idAttr = target.attributes.id;
        let value = idAttr.nodeValue;

        this.isClicked = value;
    }

    editForm() {
        this.openResult = false;
    }

    resetForm() {
        this.estimationForm.reset();
        location.reload();
        this.openResult = false;
    }

    modalPdf() {

    }

    /* Only one function with one param : 0 | 1 | 2 */
    openFirstAccordion() {
        this.accordion['groups'][0].isOpen = true;
    }

    openSecondAccordion() {
        this.accordion['groups'][1].isOpen = true;
    }

    openLastAccordion() {
        this.accordion['groups'][2].isOpen = true;
    }

    onDragEnd(marker: Marker) {
        this.lon.enable();
        this.lat.enable();
        this.address.disable();

        NgZone.assertInAngularZone();
        this.coordinates = marker.getLngLat().toArray();
        this.coordinatesLng = this.coordinates[0];
        this.coordinatesLat = this.coordinates[1];

        // this.lon.value = this.coordinatesLng;
        // this.lat.value = this.coordinatesLat;

        this.lon.patchValue(this.coordinatesLng);
        this.lat.patchValue(this.coordinatesLat);

        this.isGPSCollapsed = true;
        this.isMapCollapsed = false;
        this.addressIsVisible = false;
        this.gpsIsVisible = true;
    }

    onSubmitCoordinates() {

        this.modalRef.hide();
        this.accordion['groups'][1].isOpen = true;
    }

    toggleDetails() {
        this.displayDetails = !this.displayDetails;
    }

    onStartUpload(e) {
        this.startUpload = true;
    }

    onPrintPdf(e) {
        this.startUpload = false;
        this.files = e;
        this.isPdfLoading = true;
        if (!this.printPdf) {
            this.printPdf = true;
        }
    }

    onfinishPrint($event) {
        this.printPdf = false;
        this.isPdfLoading = false;
        this.startUpload = false;
    }

    changeValueOrientation($event, control) {
        if ($event.target.checked) {
            control.patchValue($event.target.value);
        } else {
            control.patchValue(0);
        }
        this.orientationValues = [];
        this.orientationLabels = [];

        if (this.north.value) {
            this.orientationLabels.push(this.orientationlist[0].label);
            this.orientationValues.push(this.orientationlist[0].id);
        }
        if (this.south.value) {
            this.orientationLabels.push(this.orientationlist[1].label);
            this.orientationValues.push(this.orientationlist[1].id);
        }
        if (this.east.value) {
            this.orientationLabels.push(this.orientationlist[2].label);
            this.orientationValues.push(this.orientationlist[2].id);
        }
        if (this.west.value) {
            this.orientationLabels.push(this.orientationlist[3].label);
            this.orientationValues.push(this.orientationlist[3].id);
        }
    }

    adjustPriceResult() {
        this.openModal(this.AdjustPriceResult);
    }
}
