import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'adjustPrice'})
export class AdjustPricePipe implements PipeTransform {

    transform(value: any, newValue = 0) {
        if (!newValue) {
            return value;
        }
        return value + newValue;
    }
}
