import {Injectable} from '@angular/core';
import {Observable} from 'Rxjs/Observable';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';

import * as _ from 'underscore';
import {Subscription} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class EstimationService {

    httpEmitter: Subscription;
    httpEvent: HttpEvent<{}>;
    baseUrl = 'https://bdv-api-voisine.appspot.com/v2';
    estimationHeaders;

    constructor(private http: HttpClient) {
        this.estimationHeaders = new HttpHeaders({
            'Authorization': 'Basic ' + btoa('wuestGoldenKey:BDVxxr7w820Y10YSc'),
            'Content-Type': 'application/json'
        });

    }

    getEstimation(inputs: any, referenceInputs: any): Observable<any> {
        let estimationParameters = new HttpParams();
        let allInputs = _.extend(inputs.step1, inputs.step2, inputs.step3);

        _.mapObject(allInputs, (val, key) => {
            /* make the difference between object parameters ( id and label ) and simple input */
            val = (val.id || val.id == 0) ? val.id : val;
            estimationParameters = estimationParameters.append(key, val);
        });

        _.mapObject(referenceInputs.step4, (val, key) => {
            if (val.referenceType && val.referenceType.id) {
                estimationParameters = estimationParameters.append('referenceYear' + val.referenceType.id, (new Date(val.referenceDate)).getFullYear().toString());
                estimationParameters = estimationParameters.append('referencePrice' + val.referenceType.id, val.referencePrice);
            }
        });


        let options = {params: estimationParameters, headers: this.estimationHeaders};
        return this.http.get(this.baseUrl + '/valuation/', options).map((data: any) => {
            return data;
        });
    }

    async sendFiles(files, id) {

        const dataRequest = await this.convertBas64Files(files);

        const req = new HttpRequest<any>(
            'POST',
            'https://bdv-api-voisine.appspot.com/v2/image/add',
            {...dataRequest, valuationId: id},
            {
                reportProgress: true,
                headers: this.estimationHeaders
            });

        return this.httpEmitter = this.http.request(req)
            .subscribe(
                event => {
                    this.httpEvent = event;

                    if (event instanceof HttpResponse) {
                        delete this.httpEmitter;
                        console.log('request done', event);
                    }
                },
                error => console.log(error.message, 'Error Uploading Files: ')
            );
    }

    private prepareData(data) {
        console.log('raw result', data);
        let output = {
            titleArray: [],
            imageArray: []
        };
        data.forEach(obj => {
            output.titleArray.push(obj.title);
            output.imageArray.push(obj.image);
        });
        return output;
    }

    private async convertBas64Files(files) {
        const results = [];
        files.forEach(f => {
            results.push(this.getBase64(f));
        });
        // Now that all the asynchronous operations are running, here we wait until they all complete.
        return this.prepareData(await Promise.all(results));
    }

    private getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve({
                    title: file.title,
                    image: reader.result
                });
            };
            reader.onerror = function (error) {
                reject(error);
            };
        });

    }
}
