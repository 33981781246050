import { Injectable, EventEmitter  } from '@angular/core';
import { Observable } from 'Rxjs/Observable';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import * as _ from 'underscore';

@Injectable()
export class LoginService {
  loginUpdate: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private router: Router) {

  }


  login(login: any, pass: any): Observable<any> {
    let loginHeaders = new HttpHeaders({
      'Authorization': "Basic " + btoa("wuestGoldenKey:BDVxxr7w820Y10YSc")
    })
    let options = { params: { email: login, password: pass }, headers: loginHeaders };
    return this.http.get("https://bdv-api-voisine.appspot.com/v1/users/login/", options).map((data: any) => {
      if(data.user) {
        this.saveUser(data.user);
        return data;
      } else {
        throw throwError(data);
      }
    });
  }

  logout() {
    localStorage.removeItem('user');
    this.router.navigateByUrl('/login');
    this.loginUpdate.emit();
  }

  saveUser(data) {
    localStorage.setItem('user', JSON.stringify(data));
    this.loginUpdate.emit(data);
  }

  isLoggedIn() {
    var user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      return true;
    } else {
      return false;
    }
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

}
