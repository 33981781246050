import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'frenchNumber'})
export class FrenchNumberPipe implements PipeTransform {

    transform(value:any) {
        if (value) {
          return value.toString().replace(/,/g, " ");
        }
    }
}
