import { Component, OnInit } from "@angular/core";
import { LoginService } from './services/login.service';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  user: any;

  constructor(private loginService: LoginService, private http: HttpClient) { }

  ngOnInit() {
    this.user = this.loginService.getUser();
    this.loginService.loginUpdate.subscribe((user) => {
      console.log(user);
      this.user = user;
    });
  }

  ngOnChanges() {
    console.log('rers')
  }

  logout() {
    this.loginService.logout();
  }

  openDoc() {
    window.open('https://asset.bondevisite.fr/voisine/Voisines_Manuel_Utilisateur.pdf', '_blank');
  }


}
