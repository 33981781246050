import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {AccordionModule} from 'ngx-bootstrap';
import {BsDropdownModule} from 'ngx-bootstrap';
import {BsDatepickerModule} from 'ngx-bootstrap';
import {ModalModule} from 'ngx-bootstrap';
import {TooltipModule} from 'ngx-bootstrap';
import {BarRatingModule} from 'ngx-bar-rating';
import {ReactiveFormsModule, FormsModule, FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';

import {Ng2CompleterModule} from 'ng2-completer';

import {AppComponent} from './app.component';
import {FrenchNumberPipe} from './pipes/french-number';
import {CleanNumberPipe} from './pipes/clean-number';
import {HundredCeilPipe} from './pipes/hundred-ceil';
import {FloatFormatPipe} from './pipes/float-format';
import {AutocompleteService} from './services/autocomplete.service';
import {EstimationService} from './services/estimation.service';
import {ScoringService} from './services/scoring.service';
import {LoginService} from './services/login.service';

import {ContentLoaderModule} from '@netbasal/content-loader';

import {HttpClientModule} from '@angular/common/http';
import {NgProgressModule} from '@ngx-progressbar/core';
import {NgProgressHttpModule} from '@ngx-progressbar/http';

import {NgxMapboxGLModule} from 'ngx-mapbox-gl';

import {NgxChartsModule} from '@swimlane/ngx-charts';
import {LoginComponent} from './pages/login/login.component';
import {HomeComponent} from './pages/home/home.component';
import {ExportComponent} from './export/export.component';
import {SafePipe} from './pipes/safe.pipe';
import {AdjustPricePipe} from './pipes/adjustPrice';
import {ImagesComponent} from './export/images/images.component';
import {ngfModule} from 'angular-file';

const appRoutes: Routes = [
    {path: 'login', component: LoginComponent},
    {path: '', component: HomeComponent}
];


@NgModule({
    declarations: [
        AppComponent,
        FrenchNumberPipe,
        CleanNumberPipe,
        HundredCeilPipe,
        FloatFormatPipe,
        LoginComponent,
        HomeComponent,
        ExportComponent,
        AdjustPricePipe,
        ImagesComponent,
        SafePipe
    ],
    imports: [
        NgxMapboxGLModule.withConfig({
            accessToken: 'pk.eyJ1IjoiYmR2IiwiYSI6ImNqbHdhemN5bjEyM3cza29iZG1ncWJvNm4ifQ.jafDYowzcgf9n8oimEQNBg'
        }),
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        AccordionModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        Ng2CompleterModule,
        NgxChartsModule,
        BarRatingModule,
        ContentLoaderModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        NgProgressModule,
        NgProgressHttpModule,
        ngfModule
    ],

    providers: [
        AutocompleteService,
        EstimationService,
        ScoringService,
        LoginService,
        AdjustPricePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
