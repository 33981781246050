import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'hundredCeil'})
export class HundredCeilPipe implements PipeTransform {
  transform(value:number): string {
    value = Math.round(value);
    if (value >= 1000) {
      return (Math.round(value/1000) * 1000).toLocaleString();
    } else
      return value.toLocaleString();
  }
}
