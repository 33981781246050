
import { map } from 'rxjs/operators';
import { Subject } from "rxjs";
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams
} from "@angular/common/http";
import { Observable } from 'Rxjs/Observable';

import { CompleterData, CompleterItem } from "ng2-completer";

@Injectable()
export class AutocompleteService extends Subject<CompleterItem[]> implements CompleterData {

  constructor(private http: HttpClient) {
    super();
  }
  // https://bdv-addresses-dot-bdvapis.appspot.com/v1/cities?postalCode=78600
  public search(term: string): void {
    this.http.get("https://bdv-addresses-dot-bdvapis.appspot.com/v1/search/address?address=" + term, this.getHeaders()).pipe(
      map((data: any) => {
        let matches = (<Array<any>>data.addresses).map(
          (episode: any) =>
            this.convertToItem(episode))
          .filter(episode => !!episode) as CompleterItem[];
        this.next(matches.slice(0, 6));
      }))
      .subscribe();
  }

  public cancel() {
    // Handle cancel
  }

  public convertToItem(data: any): CompleterItem | null {
    if (!data) {
      return null;
    }
    return {
      originalObject: data.road_name_code,
      inseeCode: data.code_insee,
      title: data.road_name_code
    } as CompleterItem;
  }

  public getCoordinates(address): Observable<any> {
    return this.http.get("https://bdv-addresses-dot-bdvapis.appspot.com/v1/geocode/address?address=" + address, this.getHeaders()).pipe(
      map((data: any) => {
        return data.address.result.latlng;
      }))
  }

  public getInseeCode(lon, lat): Observable<any> {
    return this.http.get("https://bdv-addresses-dot-bdvapis.appspot.com/v1/geocode/reverse?lon=" + lon + "&lat=" + lat, this.getHeaders()).pipe(
      map((data: any) => {
        return data.address.code_insee;
      }))
  }

  public getAddressFromCoordinates(lon, lat): Observable<any> {
    return this.http.get("https://bdv-addresses-dot-bdvapis.appspot.com/v1/geocode/reverse?lon=" + lon + "&lat=" + lat, this.getHeaders())
      .map((data: any) => {
        return data;
      })
  }

  getHeaders() {
    var token = "YmR2X2FkZHJlc3Nlc183ODg5OjNSZU16V3RNUk5HNEthSHE";
    const headerDict = {
      "Authorization": "Basic " + token
    }
    const headers = {
      headers: new HttpHeaders(headerDict),
    };
    return headers;
  }

}
