export const SCORES_ICONS = {
  'luminosity' : 'bdv-icon-Luminosity',
  'quietness' : 'bdv-icon-Calme',
  'pollution' : 'bdv-icon-Pollution',
  'cooking' : 'bdv-icon-Restaurant',
  'services' : 'bdv-icon-Services',
  'transportation' : 'bdv-icon-Transport',
  'health' : 'bdv-icon-Health',
  'education' : 'bdv-icon-Education',
  'shopping' : 'bdv-icon-Shopping',
  'connectivity' : 'bdv-icon-Connectivity',
  'safety' : 'bdv-icon-Security',
  'entertainment' : 'bdv-icon-PinMap'
}


export const SCORES_LABELS = {
  'luminosity' : 'Luminosité',
  'quietness' : 'Calme',
  'pollution' : 'Qualité de l\'air',
  'cooking' :  'Restaurants',
  'services' :  'Services',
  'transportation' : 'Transports',
  'health' : 'Santé',
  'education' : 'Education',
  'shopping' : 'Shopping',
  'connectivity' : 'Connectivité',
  'safety' : 'Sécurité',
  'entertainment' : 'Divertissement'
}
