import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {HttpClient} from '@angular/common/http';

import {EstimationService} from '../../services/estimation.service';


@Component({
    selector: 'app-images',
    templateUrl: './images.component.html',
    styleUrls: ['./images.component.scss']
})
export class ImagesComponent implements OnInit {
    @Input() startUpload: boolean;
    @Input() estimationResult: any;
    @Output() onFinish: EventEmitter<any> = new EventEmitter();
    @Output() onClose: EventEmitter<any> = new EventEmitter();

    @ViewChild('modalUploadImages') modalUploadImages: TemplateRef<any>;
    modalRef: BsModalRef;
    files: File[] = [];
    toggleNames = {};

    sendableFormData: FormData;//populated via ngfFormData directive

    dragFiles: any;
    validComboDrag: any;
    lastInvalids: any;
    fileDropDisabled: any;
    maxSize: any;
    baseDropValid: any;

    constructor(
        private modalService: BsModalService,
        private HttpClient: HttpClient,
        private estimatonService: EstimationService
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty('startUpload')) {
            let values: any = changes;
            let value = values.startUpload.currentValue;
            if (value == true) {
                setTimeout(() => this.openModal(this.modalUploadImages), 100);
            }
        }
    }


    openModal(modalTemplate: TemplateRef<any>) {
        this.modalRef = this.modalService.show(modalTemplate);
    }

    ngOnInit(): void {
    }


    uploadFiles()/*: Subscription*/ {
        if (this.files) {
            this.files.slice(0, 24);
            this.estimatonService.sendFiles(this.files,this.estimationResult.valuationId);
            this.onFinish.emit(this.files);
            this.hideModal();
        } else {
            this.onFinish.emit([]);
        }
    }

    updateTitle($event) {
        for (let i = 0; i < this.files.length; i++) {
            this.files[i]['title'] = this.files[i].name.replace(/\.[^/.]+$/, '');
        }
    }

    toggleEditName(index) {
        this.toggleNames[index] = !this.toggleNames[index];
    }

    hideModal() {
        this.toggleNames = {};
        this.files = [];
        this.modalRef.hide();
    }

    close() {
        this.hideModal();
        this.onClose.emit([]);
    }
}


