export const PROPERTY_TYPES: any[] = [
    {
        id: '0',
        label: 'Appartement'
    },
    {
        id: '1',
        label: 'Maison'
    }
];

export const APARTEMENT_TYPES: any[] = [
    {
        id: '0',
        label: 'Appartement en étage'
    },
    {
        id: '1',
        label: 'Duplex'
    },
    {
        id: '2',
        label: 'Attique'
    },
    {
        id: '3',
        label: 'Duplex en attique'
    },
    {
        id: '4',
        label: 'Appartement en terrasse'
    }
];

export const USAGE_TYPES: any[] = [
    {
        id: '1',
        label: 'Résidence principale'
    },
    {
        id: '0',
        label: 'Résidence secondaire'
    }
];

export const CONSTRUCTION_YEARS: any[] = [
    {
        id: '1919',
        label: 'Avant 1919'
    },
    {
        id: '1933',
        label: '1919 - 1945'
    },
    {
        id: '1959',
        label: '1946 - 1970'
    },
    {
        id: '1981',
        label: '1971 - 1990'
    },
    {
        id: '1998',
        label: '1991 - 2005'
    },
    {
        id: '2010',
        label: '2006 - 2012'
    },
    {
        id: '2012',
        label: 'Après 2012'
    },
];

export const ROOMS_NUMBER: any[] = [
    {
        id: '1',
        label: '1 pièce'
    },
    {
        id: '2',
        label: '2 pièces'
    },
    {
        id: '3',
        label: '3 pièces'
    },
    {
        id: '4',
        label: '4 pièces'
    },
    {
        id: '5',
        label: '5 pièces'
    },
    {
        id: '6',
        label: '6 pièces'
    },
    {
        id: '7',
        label: '7 pièces'
    },
    {
        id: '8',
        label: '8 pièces et plus'
    }
];

export const TOTAL_FlOORS: any[] = [
    {
        id: 1,
        label: '1 étage'
    },
    {
        id: 2,
        label: '2 étages'
    },
    {
        id: 3,
        label: '3 étages'
    },
    {
        id: 4,
        label: '4 étages'
    },
    {
        id: 5,
        label: '5 étages'
    },
    {
        id: 6,
        label: '6 étages'
    },
    {
        id: 7,
        label: '7 étages'
    },
    {
        id: 8,
        label: '8 étages et plus'
    },
];

export const APPART_FlOORS: any[] = [
    {
        id: 0,
        label: 'Rez-de-chaussée'
    },
    {
        id: 1,
        label: '1er étage'
    },
    {
        id: 2,
        label: '2ème étage'
    },
    {
        id: 3,
        label: '3ème étage'
    },
    {
        id: 4,
        label: '4ème étage'
    },
    {
        id: 5,
        label: '5ème étage'
    },
    {
        id: 6,
        label: '6ème étage'
    },
    {
        id: 7,
        label: '7ème étage'
    },
    {
        id: 8,
        label: '8ème étage et plus'
    },
];

export const ENERGY_CLASSES: any[] = [
    {
        id: 7,
        label: 'A'
    },
    {
        id: 6,
        label: 'B'
    },
    {
        id: 5,
        label: 'C'
    },
    {
        id: 4,
        label: 'D'
    },
    {
        id: 3,
        label: 'E'
    },
    {
        id: 2,
        label: 'F'
    },
    {
        id: 1,
        label: 'G'
    },
];

export const GES_CLASSES: any[] = [
    {
        id: 7,
        label: 'A'
    },
    {
        id: 6,
        label: 'B'
    },
    {
        id: 5,
        label: 'C'
    },
    {
        id: 4,
        label: 'D'
    },
    {
        id: 3,
        label: 'E'
    },
    {
        id: 2,
        label: 'F'
    },
    {
        id: 1,
        label: 'G'
    },

];

export const VIEWS_LIST: any[] = [
    {
        id: 1,
        label: 'Vis-à-vis'
    },
    {
        id: 2,
        label: 'Dégagée'
    },
    {
        id: 3,
        label: 'Exceptionnelle'
    }
];

export const ORIENTATION_LIST: any[] = [
    {
        id: 1,
        label: 'Nord'
    },
    {
        id: 2,
        label: 'Sud'
    },
    {
        id: 3,
        label: 'Est'
    },
    {
        id: 4,
        label: 'Ouest'
    }
];

export const RENOVATION_LIST: any[] = [
    {
        id: 1,
        label: 'Travaux à prévoir'
    },
    {
        id: 2,
        label: 'Rafraîchissement nécessaire'
    },
    {
        id: 3,
        label: 'Habitable en l\'état'
    },
    {
        id: 4,
        label: 'Refait à neuf'
    }
];

export const HEATING_TYPES: any[] = [
    {
        id: 0,
        label: 'Gaz'
    },
    {
        id: 1,
        label: 'Fioul Collectif'
    },
    {
        id: 2,
        label: 'Electrique Collectif'
    },
    {
        id: 3,
        label: 'Gaz Collectif'
    },
    {
        id: -1,
        label: 'Electrique'
    },
    {
        id: -2,
        label: 'Fioul '
    },
    {
        id: -3,
        label: 'Bois'
    },
    {
        id: -4,
        label: 'Pompe à chaleur'
    },
];

export const PROPERTY_STATES: any[] = [
    {
        id: 1,
        label: 'Nécessite une rénovation'
    },
    {
        id: 2,
        label: 'Détérioré'
    },
    {
        id: 3,
        label: 'Intact'
    },
    {
        id: 4,
        label: 'Bon'
    },
    {
        id: 5,
        label: 'Neuf'
    },
];

export const STANDING_RATES: any[] = [
    {
        id: 1,
        label: 'Laisse à désirer'
    },
    {
        id: 2,
        label: 'Insuffisant'
    },
    {
        id: 3,
        label: 'Moyen'
    },
    {
        id: 4,
        label: 'Elevé'
    },
    {
        id: 5,
        label: 'Luxueux'
    },
];

export const BATHROOM_TYPES: any[] = [
    {
        id: 3,
        label: 'Luxueux, Matériaux (sols et mobilier): granit, marbre, pierre fine, verre, vernis, bois véritable'
    },
    {
        id: 2,
        label: 'Moderne, Matériaux (sols et mobilier): céramique; résine synthétique de haute qualité; bois'
    },
    {
        id: 1,
        label: 'Basique, Matériaux (sols et mobilier): pierre artificielle, résine synthétique'
    },
];

export const KITCHEN_TYPES: any[] = [
    {
        id: 3,
        label: 'Plan de travail en pierre naturelle, appareillage de haut niveau (par ex. plaque induction, steamer, micro-ondes)'
    },
    {
        id: 2,
        label: 'Cuisine encastrée moderne avec plan de travail de haute qualité, appareillage courant (par ex. vitrocéramique, lave-vaisselle)'
    },
    {
        id: 1,
        label: 'Cuisine simple, plan de travail en résine synthétique, appareillage simple, cuisine non encastrée'
    },
];


export const REFERENCE_TYPES: any[] = [
    {
        id: 1,
        label: 'Transaction de gré à gré'
    },
    {
        id: 2,
        label: 'Construction neuve'
    },
    {
        id: 3,
        label: 'Valeur d\'assurance'
    },
    {
        id: 4,
        label: 'Valeur fiscale'
    },
    {
        id: 5,
        label: 'Valeur comptable'
    },
    {
        id: 6,
        label: 'Autre expertise'
    },
    {
        id: 7,
        label: 'Valeur de liquidation'
    },
    {
        id: 8,
        label: 'Spécifique au client'
    },
];
